<template>
  <div
    v-if="video.liveboxAssetId !== '' || video.ooyalaId === ''"
    class="form-edit"
  >
    <div class="row">
      <div class="col-lg-12">
        <h3 class="form-edit__header">{{ $t('video.video_content') }}</h3>
        <div v-if="showVideoUploadButton === false && !disabled" class="alert alert-info">
          {{ $t('video.video_upload_required_fields_message') }}
        </div>

        <div v-show="showVideoUploadButton">
          <span
            v-if="video.liveboxAssetId !== '' && ['live','published'].some(v => v === video.status)"
            class="form-edit__label"
          >
            {{ $t('video.uploaded_video') }}
          </span>
          <span
            v-else
            class="form-edit__label"
          >
            {{ $t('video.upload_video') }}
          </span>
          <div
            v-if="publishedOrLive"
            class="text-center"
          >
            <EagleVideo :video="video" />
            <LiveboxUploadButton
              v-if="!disabled"
              ref="livebox-upload-button"
              :video="video"
              :thumbnail-position="String(thumbnailPosition)"
              @load-video="setThumbnail"
            />
          </div>

          <ModuleFormPhotoBox
            v-else-if="video.status !== 'processing'"
            key="video"
            :disabled="disabled"
            :image="video.expanded.previewImage"
            :select-click="uploadVideo"
            :select-text="$t('media.select_video')"
          >
            <template v-if="!disabled" #buttons>
              <LiveboxUploadButton
                ref="livebox-upload-button"
                :video="video"
                :thumbnail-position="String(thumbnailPosition)"
                @load-video="setThumbnail"
              />
            </template>
          </ModuleFormPhotoBox>

          <div
            v-else-if="video.liveboxAssetId !== '' && video.status === 'processing'"
            class="upload-video-box upload-video-box--processing"
            @click="() => selectClick ? selectClick() : $refs.uploadButton.openFileDialog()"
          >
            <div class="upload-video-box__processing">
              <svg
                  class="circular"
                  viewBox="25 25 50 50"
                >
                  <circle
                    class="path"
                    cx="50"
                    cy="50"
                    r="20"
                    fill="none"
                    stroke-width="2"
                    stroke-miterlimit="10"
                  />
                </svg>
                {{ $t('video.video_is_processing') }}
            </div>
          </div>

          <div
            v-if="publishedOrLive"
            class="form-edit__preview"
          >
            <span class="form-edit__label">
              {{ $t('video.preview_image') }}
            </span>

            <ModuleFormPhotoBox
              key="video"
              :disabled="disabled"
              :image="video.expanded.previewImage"
              :media-usage-type="mediaUsageType"
              :select-click="openFileDialog"
              @set-media="setImage"
              @remove-media="removeImage"
            >
              <template v-if="!disabled" #buttons>
                <MediaUploadButton
                  ref="uploadButton"
                  class="buttons-group__button"
                  :button-text="$t('buttons.upload_photo_from_computer')"
                  :media-usage-type="mediaUsageType"
                  @set-media="setImage"
                />
                <MediaSelectButton
                  class="buttons-group__button"
                  :button-text="$t('buttons.select_photo_from_bank')"
                  :media-usage-type="mediaUsageType"
                  @set-media="setImage"
                />
                <MediaUrlButton
                  class="buttons-group__button"
                  type-of-btn="url"
                  :btn-text="$t('buttons.upload_photo_from_url')"
                  :media-usage-type="mediaUsageType"
                  @set-media="setImage"
                />
              </template>
            </ModuleFormPhotoBox>
          </div>
          <div v-if="video.status === 'new' && videoSrc">
            <span class="form-edit__label">
              {{ $t('video.preview_image') }}
            </span>
            <VideoThumbnail
              :video-src="videoSrc"
              ref="video-thumbnail"
              @set-thumbnail="continueUploadVideo"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideoThumbnail from './VideoThumbnail.vue'
import ModuleFormPhotoBox from '@/components/shared/ModuleFormPhotoBox'
import { MEDIA_USAGE_TYPE_VIDEO } from '@/model/ValueObject/MediaUsageTypes'
import EagleVideo from '@/components/shared/EagleVideo'
import LiveboxUploadButton from '../../components/shared/LiveboxUploadButton'
import MediaUploadButton from '@/components/shared/MediaUploadButton'
import MediaSelectButton from '@/components/shared/MediaSelectButton'
import MediaUrlButton from '@/components/shared/MediaUrlButton'

export default {
  name: 'VideoUpload',
  components: {
    VideoThumbnail,
    ModuleFormPhotoBox,
    EagleVideo,
    LiveboxUploadButton,
    MediaUploadButton,
    MediaSelectButton,
    MediaUrlButton
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    video: {
      type: Object,
      required: true
    },
    validations: {
      type: Object,
      required: true
    }
  },
  computed: {
    publishedOrLive () {
      return this.video.liveboxAssetId !== '' && ['live', 'published'].some(v => v === this.video.status)
    },
    showVideoUploadButton () {
      if (this.video.status === 'published') {
        return true
      }
      if (this.validations.$invalid) {
        return false
      }
      return true
    }
  },
  data () {
    return {
      videoSrc: '',
      thumbnailPosition: '50',
      mediaUsageType: MEDIA_USAGE_TYPE_VIDEO,
      files: null,
      dataLoaded: true,
      isLoading: false
    }
  },
  methods: {
    openFileDialog () {
      this.$refs.uploadButton.openFileDialog()
    },
    continueUploadVideo () {
      this.thumbnailPosition = this.$refs['video-thumbnail'].percentualPosition
      this.$refs['livebox-upload-button'].readFiles(this.files)
    },
    setThumbnail (event) {
      this.files = event.target.files
      this.videoSrc = URL.createObjectURL(event.target.files[0])
    },
    setImage (medias) {
      medias.forEach(media => {
        this.video.expanded.previewImage = media
        this.video.previewImage = media.id
      })
    },
    removeImage () {
      this.video.expanded.previewImage = {}
      this.video.previewImage = null
    },
    uploadVideo () {
      this.$refs['livebox-upload-button'].click()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .input-area.form-group  {
    text-align: left;
    margin-top: 1rem;
  }
  #liveboxUploadButton {
    margin-top: 1rem;
  }
  .separator {
    display: none;
  }
  .buttons-group {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.form-edit {
  font-family: "Roboto", sans-serif;
  width: 100%;
  max-width: 736px;
  &__header {
    color: #465674;
    font: {
      size: rem(22px);
      weight: 400;
    }
  }
  &__label {
    color: #8A96AC;
    font: {
      size: rem(14px);
      weight: 400;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  &__preview {
    max-width: 376px;
  }
}
.upload-video-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  background-color: #FFFFFF;
  border: solid #D1DBE4 rem(1px);
  border-radius: rem(5px);
  margin-bottom: 1rem;
  color: #465674;
  cursor: pointer;
  &--processing {
    cursor: default;
  }
  &__processing {
    display: flex;
    .circular {
      animation: rotate 2s linear infinite;
      height: 25px;
      transform-origin: center center;
      width: 25px;
      position: initial;
      margin: 0 1rem;
      .path {
        stroke: #465674 !important;
      }
    }
  }
  &__icon-box {
    display: flex;
    justify-content: center;
    padding-bottom: rem(8px);
  }
  &__icon {
    @include size(rem(30px));
    fill: #D3DCEC;
    float: left;
  }
}
</style>
