<template>
  <div class="video-annotations">
    <div class="row video-annotations__content">
      <div class="col-lg-12">
        <h3 class="form-edit-header">{{ $t('video.annotations') }}</h3>
      </div>

      <div class="col-lg-8">
        <p class="alert alert-info" v-if="video.annotations.length === 0 && disabled">
          {{ $t('video.annotations_not_found') }}
        </p>
        <div id="annotations-list">
          <VideoAnnotation
            v-for="(value, index) in video.annotations" :key="index"
            ref="annotations"
            :video="video"
            :disabled="disabled"
            :annotation-data="value"
            :annotation-number="index + 1"
            @remove="removeAnnotation($event); updateAnnotations(-1);"
            @update:annotation="updateAnnotations"
          />
        </div>

        <div
          v-if="!disabled"
          class="card"
        >
          <div class="card-body">
            <div class="video-annotations__new">
              <h5>#{{ annotationNumber }} {{ $t('video.new_annotation') }}</h5>
              <div>
                <button
                  class="btn btn-sm btn-info"
                  data-test="video_annotation_btn_new"
                  @click="createNewAnnotation('external')"
                >
                  <i class="fa fa-plus"></i> {{ $t('video.annotation_web') }}
                </button>
                <button
                  class="btn btn-sm btn-info"
                  data-test="video_annotation_btn_new"
                  @click="createNewAnnotation('video')"
                >
                  <i class="fa fa-plus"></i> {{ $t('video.annotation_video') }}
                </button>
                <button
                  class="btn btn-sm btn-info"
                  data-test="video_annotation_btn_new"
                  @click="createNewAnnotation('article')"
                >
                  <i class="fa fa-plus"></i> {{ $t('video.annotation_article') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <span class="form-edit__label">
          {{ $t('video.preview_annotations') }}
        </span>
        <EagleVideo :video="video" />
        <VideoAnnotationGantt :video="video" :key="ganttKey" />
      </div>
    </div>
  </div>
</template>

<script>
import VideoAnnotationModel from '../../model/VideoAnnotation'
import VideoAnnotation from './VideoAnnotation.vue'
import EagleVideo from '@/components/shared/EagleVideo'
import VideoAnnotationGantt from '../../components/video/VideoAnnotationGantt'

export default {
  name: 'VideoAnnotations',
  components: {
    VideoAnnotation,
    EagleVideo,
    VideoAnnotationGantt
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    video: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      ganttKey: -1,
      videoAnnotation: { ...VideoAnnotationModel }
    }
  },
  computed: {
    hasAnnotations () {
      return this.video.annotations.length > 0
    },
    annotationNumber () {
      return this.video.annotations.length + 1
    }
  },
  methods: {
    updateAnnotations (index, annotation) {
      if (annotation) {
        this.video.annotations[index] = annotation
      }
      this.ganttKey = index
    },
    createNewAnnotation (type) {
      this.videoAnnotation.type = type
      this.video.annotations.push({ ...this.videoAnnotation })
      this.$nextTick(() => {
        const annotations = this.$refs.annotations
        const lastAnnotation = annotations[annotations.length - 1]
        if (lastAnnotation && lastAnnotation.$refs['collapse-btn']) {
          lastAnnotation.$refs['collapse-btn'].click()
        }
      })
    },
    removeAnnotation (id) {
      this.video.annotations.splice(id, 1)
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
h5 {
  font: 700 1rem "Roboto";
  color: #465674;
  text-transform: uppercase;
  margin: 0;
}
.form-edit-header {
  font: 400 rem(22px) "Roboto";
  color: #465674;
}
.video-annotations {
  width: 100%;
  &__new {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
