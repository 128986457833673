<template>
  <Paginate
    :value="value"
    @input="$emit('input', $event)"
    :page-count="pageCount"
    :page-range="5"
    :margin-pages="2"
    :click-handler="clickHandler"
    :prev-text="$t('page_prev')"
    :next-text="$t('page_next')"
    :container-class="'pagination'"
    :prev-class="'paginate_button previous'"
    :next-class="'paginate_button next'"
    :page-class="'paginate_button'"
  />
</template>

<script>
import Paginate from 'vuejs-paginate'

export default {
  name: 'Pagination',
  props: {
    value: {
      type: Number,
      required: true
    },
    pageCount: {
      type: Number,
      required: true
    },
    clickHandler: {
      type: Function,
      required: true
    }
  },
  components: {
    Paginate
  }
}
</script>

<style lang="scss">
  .pagination {
    font-family: "Roboto", sans-serif;
    font-size: rem(15px);
    font-weight: 400;
    color: #465674;
    display: flex;
    align-items: center;
    margin: 0;
    .paginate_button {
      &.previous {
        margin-right: rem(15px);
      }
      &.next {
        margin-left: rem(15px);
      }
      &:not(.previous):not(.next):not(.disabled) {
        > a {
          padding: rem(4px) rem(10px);
          display: inline-block;
          border-radius: rem(2px);
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
        }
      }
      > a {
        &:hover {
          background: transparent;
          color: #6599FE;
        }
      }
      &.disabled {
        opacity: .6;
        > a {
          pointer-events: none;
        }
      }
      &.active {
        > a {
          background: #6599FE;
          color: #fff;
          pointer-events: none;
        }
      }
    }
  }
</style>
