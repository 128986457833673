<template>
  <div class="card">
    <div class="card-header" :id="`annotation-${annotationNumber}`">
      <h5 class="mb-0">
        {{ annotationTitle }}
      </h5>
      <div class="d-flex">
        <ModuleFormButton
          v-if="showSaveAnnotation || !annotation.id"
          :title="$t('buttons.save')"
          class="button-save"
          @click="saveAnnotation"
        />
        <ModuleFormButton
          icon="delete"
          class="mx-2"
          @click="annotationDeleteModal = true"
        />
        <button
          ref="collapse-btn"
          class="collapse-button"
          data-toggle="collapse"
          :data-target="`#collapse-annotation-${annotationNumber}`"
          :aria-expanded="true"
          :aria-controls="`collapse-annotation-${annotationNumber}`"
        >
          <ModuleFormButton>
            <IconArrowDown class="module-form-button__icon module-form-button_arrow" />
          </ModuleFormButton>
        </button>
      </div>
      <div
        class="form-header-info"
      >
        <table>
          <tr>
            <th>{{ $t('video.numberOfViews') }}</th>
            <th>{{ $t('video.numberOfClicks') }}</th>
            <th>{{ $t('video.ctr') }}</th>
            <th>{{ $t('video.type') }}</th>
            <th>{{ $t('video.annotation_position') }}</th>
            <th>{{ $t('video.time_section') }}</th>
          </tr>
          <tr>
            <td>{{ annotationStats.displays }}</td>
            <td>{{ annotationStats.clicks }}</td>
            <td>{{ annotationStats.conversionRate }}</td>
            <td>{{ annotation.type }}</td>
            <td>{{ annotation.position }}</td>
            <td>{{ annotation.start | formatMilliseconds }}{{ ` - ` }}{{ annotation.end | formatMilliseconds }}</td>
          </tr>
        </table>
      </div>
    </div>

    <div
      :id="`collapse-annotation-${annotationNumber}`"
      class="collapse"
      :aria-labelledby="`annotation-${annotationNumber}`"
      data-parent="#annotations-list">
      <div class="card-body">
        <app-preloader-full v-if="!dataLoaded"></app-preloader-full>
        <!-- TYPE -->
        <h6>{{ $t('video.annotation') }}</h6>
        <label
          v-for="(annotationType, index) in videoAnnotationTypes"
          :key="`item-${index}`"
          class="btn btn-toggle"
          :class="{ 'active': annotation.type == annotationType.id }"
          >
          <input
            id="annotation_type"
            type="radio"
            v-model="annotation.type"
            :value="annotationType.id"
            :data-test="'type_' + annotationType.id"
            >
            {{ annotationType.title }}
        </label>

        <!-- INFORMATIONS -->
        <h6>{{ $t('video.annotation_info') }}</h6>
        <div
          v-if="annotation.type === 'video'"
          class="row"
        >
          <div class="col-lg-6">
            <app-input
              v-model.trim="annotation.annotationVideoId"
              @blur="$v.annotation.annotationVideoId.$touch()"
              :error="$v.annotation.annotationVideoId.$error"
              id="annotation_videoId"
              :required="annotation.type === 'video'"
              :label="$t('video.annotation_video_id')"
              >
            </app-input>
          </div>
          <div class="col-lg-6">
            <button class="btn btn-info m-t-30"
                    @click="showVideoListModal"
            >
              <i class="fas fa-video"></i> {{ $t('buttons.select_video') }}
            </button>
          </div>
        </div>
        <app-video-list-modal
          v-if="videoListModal"
          @set-data="setVideo"
          @close="closeVideoListModal"
          >
        </app-video-list-modal>
        <app-input
          v-model.trim="annotation.title"
          @blur="$v.annotation.title.$touch()"
          :error="$v.annotation.title.$error"
          id="annotation_title"
          :label="$t('video.annotation_title')"
          :maxlength="40"
          required
          show-counter
          >
        </app-input>
        <app-input
          v-if="annotation.type !== 'video'"
          v-model.trim="annotation.url"
          @blur="$v.annotation.url.$touch()"
          :error="$v.annotation.url.$error"
          id="annotation_url"
          :required="annotation.type !== 'video'"
          :label="$t('video.annotation_url')"
        >
        </app-input>
        <app-textarea
          v-if="annotation.type === 'article'"
          v-model="annotation.perex"
          @blur="$v.annotation.perex.$touch()"
          :error="$v.annotation.perex.$error"
          id="annotation_perex"
          :label="$t('video.meta_description')"
          :rows=2
          :maxlength="60"
          :required="annotation.type === 'article'"
          show-counter
          >
        </app-textarea>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group d-flex flex-column align-items-start">
              <label>{{ $t('video.annotation_image') }}</label>
              <div class="media-img">
                <app-media-editable
                  v-if="annotation.image"
                  :media="annotation.expanded.image"
                  :width="360"
                  :height="202"
                  @remove-media="removeImage"
                  class="m-t-10"
                  >
                </app-media-editable>
                <div class="mt-2">
                  <app-media-select-button @set-media="setImage"></app-media-select-button>
                  <app-media-upload-button @set-media="setImage"></app-media-upload-button>
                  <app-media-url-button @set-media="setImage"></app-media-url-button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group d-flex flex-column align-items-start">
              <label>{{ $t('video.annotation_image_mobile') }}</label>
              <div class="media-img">
                <app-media-editable
                  v-if="annotation.imageMobile"
                  :media="annotation.expanded.imageMobile"
                  :width="360"
                  :height="202"
                  @remove-media="removeImageMobile"
                  class="m-t-10"
                  >
                </app-media-editable>
                <div class="mt-2">
                  <app-media-select-button @set-media="setImageMobile"></app-media-select-button>
                  <app-media-upload-button @set-media="setImageMobile"></app-media-upload-button>
                  <app-media-url-button @set-media="setImageMobile"></app-media-url-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr>
        <!-- RANGE -->
        <h6>{{ $t('video.annotation_range') }}</h6>
        <app-video-annotation-range-slider
          v-if="dataLoaded"
          :video="video"
          :start="annotation.start"
          :end="annotation.end"
          @change="updateRange"
        >
        </app-video-annotation-range-slider>
        <hr>
        <!-- VISUALIZATION -->
        <h6>{{ $t('video.annotation_visualization') }}</h6>
          <div class="row colours">
            <div class="col-xl-4">
              <app-input
              v-model="annotation.border"
                id="annotation_border"
                :label="$t('video.annotation_border')"
              />
              <app-video-annotation-position
                :annotation="annotation"
              />
            </div>
            <div class="col-xl-8">
              <div class="row">
                <div class="col-sm-6 color-picker">
                  <app-color-picker
                    v-if="dataLoaded"
                    v-model.trim="annotation.bgColor"
                    id="annotation_bg_color"
                    :label="$t('video.annotation_bg_color')"
                  />
                </div>
                <div class="col-sm-6 color-picker">
                  <app-color-picker
                    v-if="dataLoaded"
                    v-model.trim="annotation.color"
                    id="annotation_color"
                    :label="$t('video.annotation_color')"
                  />
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    <app-video-annotation-delete-modal
      :annotation="annotation"
      v-if="annotationDeleteModal"
      @delete-record="removeAnnotation"
      @close="annotationDeleteModal = false"
    >
    </app-video-annotation-delete-modal>
  </div>
</template>

<script>
import ModuleFormButton from '../shared/ModuleFormButton.vue'
import IconArrowDown from '@/assets/img/svg/arrow-down.svg?inline'
import Input from '@/components/form/inputs/Input'
import Textarea from '@/components/form/Textarea'
import ColorPicker from '@/components/form/ColorPicker'
import MediaSelectButton from '@/components/shared/MediaSelectButton'
import MediaUploadButton from '@/components/shared/MediaUploadButton'
import MediaUrlButton from '@/components/shared/MediaUrlButton'
import MediaEditable from '@/components/shared/MediaEditable'
import { required, requiredIf, minLength, maxLength, url } from 'vuelidate/lib/validators'
import VideoAnnotationTypes from '../../model/ValueObject/VideoAnnotationTypes'
import VideoAnnotationRangeSlider from '../../components/video/VideoAnnotationRangeSlider'
import VideoAnnotationPosition from '../../components/video/VideoAnnotationPosition'
import PreloaderFull from '@/components/preloader/PreloaderFull'
import VideoListModal from '../../components/video/VideoListModal'
import EdonApi from '../../api/edon'
import NotifyService from '../../services/NotifyService'
import VideoAnnotationDeleteModal from './VideoAnnotationDeleteModal'
import CoreApi from '@/api/core'

export default {
  name: 'VideoAnnotation',
  components: {
    ModuleFormButton,
    IconArrowDown,
    appInput: Input,
    appTextarea: Textarea,
    appMediaSelectButton: MediaSelectButton,
    appMediaUploadButton: MediaUploadButton,
    appMediaUrlButton: MediaUrlButton,
    appMediaEditable: MediaEditable,
    appColorPicker: ColorPicker,
    appVideoAnnotationRangeSlider: VideoAnnotationRangeSlider,
    appVideoAnnotationPosition: VideoAnnotationPosition,
    appVideoListModal: VideoListModal,
    appPreloaderFull: PreloaderFull,
    appVideoAnnotationDeleteModal: VideoAnnotationDeleteModal
  },
  props: {
    video: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    annotationData: {
      type: Object,
      required: true
    },
    annotationNumber: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      annotation: this.annotationData,
      annotationDeleteModal: false,
      annotationStats: {},
      dataLoaded: true,
      error: false,
      videoAnnotationTypes: VideoAnnotationTypes,
      videoListModal: false,
      disableChangeWatcher: false,
      showSaveAnnotation: false
    }
  },
  computed: {
    annotationTitle () {
      return `#${this.annotationNumber} ${this.$t('video.annotation')}${this.annotation.title !== '' ? `: ${this.annotation.title}` : ''}`
    }
  },
  validations: {
    annotation: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(250)
      },
      perex: {
        required: requiredIf(vm => {
          return vm.type === 'article'
        })
      },
      annotationVideoId: {
        required: requiredIf(vm => {
          return vm.type === 'video'
        })
      },
      url: {
        url,
        required: requiredIf(vm => {
          return vm.type === 'external' || vm.type === 'article'
        })
      },
      start: {
        required
      },
      end: {
        required
      }
    }
  },
  watch: {
    annotationData (newValue) {
      this.annotation = newValue
    },
    annotation: {
      deep: true,
      handler () {
        if (!this.disableChangeWatcher) {
          this.showSaveAnnotation = true
        }
      }
    }
  },
  methods: {
    saveAnnotation () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      let videoAnnotationUrl = 'videoAnnotation/'
      let notifyMessage = 'created'

      if (!this.annotation.id) {
        videoAnnotationUrl = `${videoAnnotationUrl}create`
      } else {
        videoAnnotationUrl = `${videoAnnotationUrl}update`
        notifyMessage = 'updated'
      }

      this.$store.dispatch(videoAnnotationUrl, this.prepareRequest(this.annotation))
        .then(() => {
          NotifyService.setSuccessMessage(this.$t(`notify.record_was_${notifyMessage}`))
          this.showSaveAnnotation = false
          if (videoAnnotationUrl.includes('create')) {
            this.disableChangeWatcher = true
            this.annotation = { ...this.$store.getters['videoAnnotation/detail'] }
            this.$emit('update:annotation', this.annotationNumber - 1, this.annotation)
            this.$nextTick(() => {
              this.disableChangeWatcher = false
            })
          }
        })
        .catch(error => {
          console.error(error)
        })
    },
    removeAnnotation () {
      if (!this.annotation.id) {
        this.$emit('remove', this.annotationNumber - 1)
      } else {
        this.$store.dispatch('videoAnnotation/deleteRecord', this.prepareRequest(this.annotation))
          .then(() => {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$emit('remove', this.annotationNumber - 1)
          })
          .catch(error => {
            console.error(error)
          })
      }
    },
    prepareRequest (annotation) {
      annotation.videoId = parseInt(this.$route.params.id)
      return annotation
    },
    setImage (medias) {
      medias.forEach(media => {
        this.annotation.image = media.id
        this.annotation.expanded.image = media
      })
    },
    removeImage () {
      this.annotation.image = null
      this.annotation.expanded.image = null
    },
    setImageMobile (medias) {
      medias.forEach(media => {
        this.annotation.imageMobile = media.id
        this.annotation.expanded.imageMobile = media
      })
    },
    removeImageMobile () {
      this.annotation.imageMobile = null
      this.annotation.expanded.imageMobile = null
    },
    updateRange (range) {
      this.annotation.start = range[0]
      this.annotation.end = range[1]
    },
    async setVideo (video) {
      if (video.id) {
        this.annotation.annotationVideoId = video.id
      }
      this.annotation.title = video.meta.title
      if (video.expanded.previewImage) {
        await CoreApi().post('/image', video.expanded.previewImage)
          .then(response => {
            this.setImage([response.data])
          })
          .catch(error => {
            console.error(error)
            NotifyService.setErrorMessage(error)
          })
      }
    },
    showVideoListModal () {
      this.videoListModal = true
    },
    closeVideoListModal () {
      this.videoListModal = false
    },
    getAnnotationStats () {
      EdonApi().get('/videoStats/annotation/' + this.annotation.id)
        .then(response => {
          this.annotationStats = response.data
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  beforeMount () {
    this.annotation.start = !this.annotation.id ? 0 : this.annotationData.start || 0
    this.annotation.end = !this.annotation.id ? this.video.videoDuration : this.annotationData.end || this.video.videoDuration
  },
  created () {
    if (this.disabled) {
      this.getAnnotationStats()
    }
  }
}
</script>

<style lang="scss" scoped>
h5 {
  font: 700 1rem "Roboto";
  color: #465674;
  text-transform: uppercase;
  margin: 0;
}
h6 {
  font: 700 1rem "Roboto";
  color: #465674;
  &:not(:first-child) {
    margin-top: 2rem;
  }
}
.button-save {
  background-color: #6599FE !important;
}
::v-deep {
  .vue-slider-dot-handle {
    background-color: #9DAAC4;
    &::after {
      content: "III";
      position: absolute;
      left: 0.2rem;
      top: -0.15rem;
    }
  }
  .vue-slider-rail {
    background-color: #F5F5F9;
    .vue-slider-process {
      background-color: #D8DFEA;
    }
  }
}
.card-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.collapse-button {
  border: 0;
  background: transparent;
  padding: 0;
  &.collapsed {
    .module-form-button_arrow {
      transform: rotate(90deg);
    }
  }
}
.module-form-button {
  background-color: #FFFFFF;
  &_delete {
    background-color: #E9596F;
  }
  &_arrow {
    color: #9DAAC4;
    fill: #9DAAC4;
    transform-origin: center;
    transition: transform 0.2s ease-in-out;
  }
}
.media-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.color-picker {
  display: flex;
  justify-content: center;
  & > .form-group {
    display: flex;
    flex-direction: column;
    ::v-deep {
      .input-group {
        flex-direction: column;
        #annotation_bg_color,
        #annotation_color {
          width: 14.063rem;
        }
      }
    }
  }
}
.form-header-info {
  width: 100%;
  margin: 0.5rem 0;
  background-color: #F5F5F9 !important;
  padding: rem(10px) !important;
}
@media all and (max-width: 1400px) and (min-width: 1199px) {
  .colours {
    flex-direction: column;
    > div {
      flex: 100%;
      max-width: 100%;
    }
  }
}
</style>
