<template>
    <span>
        <button
          class="btn btn-info"
          id="liveboxUploadButton"
          @click="click"
          :disabled="disabled"
        >
            <i class="fas fa-upload"></i>
            {{ $t('buttons.upload_from_computer') }}
        </button>
        <input
          type="file"
          ref="videoFile"
          style="display: none"
          @change="readFilesOnInputChange"
          accept="video/*"
          data_test="video_upload"
        >
        <app-livebox-multipart-upload-modal
          v-if="liveboxUploadModal"
          :files="uploadedFiles"
          :video="video"
          :thumbnailPosition="thumbnailPosition"
          @close="closeLiveboxMultipartUploadModal"
        >
        </app-livebox-multipart-upload-modal>
    </span>
</template>

<script>
import LiveboxMultipartUploadModal from '../shared/LiveboxMultipartUploadModal'

export default {
  name: 'LiveboxUploadButton',
  props: {
    video: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    },
    thumbnailPosition: {
      type: String,
      default: '50'
    }
  },
  data () {
    return {
      dataLoaded: false,
      liveboxUploadModal: false,
      uploadedFiles: []
    }
  },
  components: {
    appLiveboxMultipartUploadModal: LiveboxMultipartUploadModal
  },
  methods: {
    readFilesOnInputChange (event) {
      this.$emit('load-video', event)
    },
    click () {
      this.$refs.videoFile.click()
    },
    readFiles (files) {
      this.uploadedFiles = []
      this.files = files
      this.uploadedFiles.push(this.files[0])
      this.showLiveboxMultipartUploadModal()
      // Clear input file value, prevent to open same file
      this.$refs.videoFile.value = ''
    },
    showLiveboxMultipartUploadModal () {
      this.liveboxUploadModal = true
    },
    closeLiveboxMultipartUploadModal () {
      this.liveboxUploadModal = false
    }
  }
}
</script>
