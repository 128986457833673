<template>
  <div class="video-articles">
    <div
      v-for="(article, index) in articles" :key="`item-${index}`"
      class="video-article"
    >
      <div class=" video-article__title">
        {{ article.field.title }}
      </div>
      <div>
        <div class="video-article__stats">
          <span class="video-article__stats-date">{{ article.orderDate | prettyDateTime }}</span>
          <div class="video-article__stats-symbols">
            <ArticleStatus
              :article="article"
              class="video-article__stats-status"
            />
            <router-link
              tag="a"
              class="btn btn-default btn-sm video-article__stats-info detail-icon"
              target="_blank"
              :title="article.documentId"
              :to="{ name: 'article_detail', params: { id: article.id }}"
            >
              <div class="info-circle">
                <i class="fa fa-info"></i>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CoreApi from '../../api/core'
import ArticleStatus from '../article/ArticleStatus'

export default {
  props: {
    video: {
      type: Object
    }
  },
  data () {
    return {
      articles: []
    }
  },
  components: {
    ArticleStatus
  },
  methods: {
    getArticlesByIds (articleIds) {
      CoreApi().get('article?view=minimal&filter_in[documentId]=' + articleIds)
        .then(response => {
          this.articles = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  created () {
    const videoArticleIds = []
    this.video.articles.forEach(article => {
      videoArticleIds.push(article.articleDocId)
    })
    this.getArticlesByIds(videoArticleIds)
  }
}
</script>

<style lang="scss" scoped>
.video-article {
  display: flex;
  background-color: #fff;
  margin: 1rem 0;
  padding: 0.2rem 0.5rem;
  align-items: center;
  border: solid 1px #d1dbe4;
  border-radius: 6px;
  &__title {
    padding-right: 1rem;
    font-weight: 700;
  }
  &__stats {
    display: flex;
    align-items: center;
    &-symbols {
      display: flex;
      align-items: center;
    }
    &-date {
      white-space: nowrap;
    }
    &-status {
      padding: 1rem;
    }
    &-info {
      display: flex !important;
      width: 1.5rem;
      height: 1.5rem;
      align-items: center;
      justify-content: center;
      padding: 0.5rem 0 !important;
      .info-circle {
        background-color: #9DAAC4;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 0.6rem;
      }
    }
    @media screen and (max-width: 1300px) {
      flex-direction: column;

      &-status {
        padding-left: 0;
      }
    }
  }
}
</style>
