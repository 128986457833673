<template>
  <div>
    <div class="row">
      <div class="col-lg-12 d-flex justify-content-between align-items-center">
        <h3 class="form-edit-header">
          {{ `${$t('video.statistics')} - ${video.meta.title}` }}
        </h3>
        <ButtonCsvExport
          :data="getStatsExportData()"
          filename="video-stats"
        />
      </div>
      <div class="col-md-3 col-sm-6">
        <Datepicker
          type="date"
          v-model="filter.startDate"
          id="filter_startDate"
          :label="$t('videoStats.filter.date_from')"
          disable-form-group
        />
      </div>
      <div class="col-md-3 col-sm-6">
        <Datepicker
          type="date"
          v-model="filter.endDate"
          id="filter_endDate"
          :label="$t('videoStats.filter.date_to')"
          disable-form-group
        />
      </div>
    </div>
    <div class="row" v-if="videoStatsData">
      <div class="col-lg-8">
        <div style="height: 330px;">
          <Preloader v-if="loading" />
          <LineChart
            :chart-data="videoStatsChartData"
            :height="330"
            :max-labels="24"
            :display-legend="false"
          />
          <div class="tab">
            <VideoStatsInfoValue
              v-for="(statsValue, index) in legendData"
              :key="`stats-value-${statsValue.label}-${index}`"
              :video-stats-data="videoStatsData"
              :chart-colors="chartColors"
              :stats-value="statsValue"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-4 video-preview mt-lg-0">
        <EagleVideo :video="video" />
      </div>
    </div>
    <div class="row" style="margin-top: 5rem">
      <div class="col-lg-12">
        <h3 class="form-edit-header">{{ $t('video.playerPerformance') }}</h3>
        <p
          v-if="videoAssetPlayerPerfData.length === 0"
          class="alert alert-info"
        >
          {{ $t('videoStats.no_data_found') }}
        </p>

        <AbstractDataTable
          v-else
          :headers="tableHeaders"
          :items="videoAssetPlayerPerfData"
          :showActions="false"
          :showPagination="false"
          table-css-class="video-performance-table"
          >
          <template #tableBody="{ item }">
            <TitleColumn
              :title-header="tableHeaders[0].name"
              :title-value="`${item.brand} ${item.videoPlayerPosition}`"
            />
            <TitleColumn
              :title-header="tableHeaders[1].name"
              :title-value="item.videoPlays"
            />
            <TitleColumn
              :title-header="tableHeaders[2].name"
              :title-value="item.videoStarts"
            />
            <TitleColumn
              :title-header="tableHeaders[3].name"
              :title-value="item.replays"
            />
            <TitleColumn
              :title-header="tableHeaders[4].name"
              :title-value="item.videoViews"
            />
            <TitleColumn
              :title-header="tableHeaders[5].name"
              :title-value="item.pass25Plays"
            />
            <TitleColumn
              :title-header="tableHeaders[6].name"
              :title-value="item.pass50Plays"
            />
            <TitleColumn
              :title-header="tableHeaders[7].name"
              :title-value="item.pass75Plays"
            />
            <TitleColumn
              :title-header="tableHeaders[8].name"
              :title-value="item.pass100Plays"
            />
            <TitleColumn
              :title-header="tableHeaders[9].name"
              :title-value="item.avgPassPerc"
            />
          </template>
        </AbstractDataTable>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Preloader from '@/components/preloader/Preloader'
import { dataHubVideoApi } from '@/api/datahub'
import Datepicker from '@/components/form/Datepicker'
import LineChart from '@/components/chart/LineChart'
import { chartColors } from '@/services/videoStats/VideoChartColors'
import ButtonCsvExport from '@/components/shared/ButtonCsvExport'
import VideoStatsInfoValue from '@/components/video/videoStats/VideoStatsInfoValue'
import AbstractDataTable from '@/components/table/AbstractDataTable'
import TitleColumn from '@/components/table/columns/TitleColumn'
import EagleVideo from '@/components/shared/EagleVideo'

export default {
  name: 'VideoStats',
  props: {
    video: {
      type: Object
    }
  },
  components: {
    VideoStatsInfoValue,
    Preloader,
    Datepicker,
    LineChart,
    ButtonCsvExport,
    TitleColumn,
    EagleVideo,
    AbstractDataTable
  },
  data () {
    return {
      loading: false,
      chartColors: chartColors,
      videoStatsData: null,
      videoAssetPlayerPerfData: [],
      filter: {
        startDate: this.getDefaultStartDate(),
        endDate: this.getDefaultEndDate()
      },
      tableHeaders: [
        { name: this.$t('video.player') },
        { name: this.$t('videoStats.videoplays') },
        { name: this.$t('videoStats.video_starts') },
        { name: this.$t('videoStats.replays') },
        { name: this.$t('videoStats.videoviews') },
        { name: this.$t('videoStats.pass25Plays') },
        { name: this.$t('videoStats.pass50Plays') },
        { name: this.$t('videoStats.pass75Plays') },
        { name: this.$t('videoStats.pass100Plays') },
        { name: this.$t('videoStats.avgPassPerc') }
      ],
      legendData: [
        { label: 'videoPlays', propName: 'videoPlays', hideAverage: true },
        { label: 'videoStarts', propName: 'videoStarts', hideAverage: true },
        { label: 'replays', propName: 'replays', hideAverage: true },
        { label: 'videoviews', propName: 'videoViews', hideAverage: true },
        { label: 'adStarts', propName: 'adStarts', hideAverage: true },
        { label: 'concurrentUsers', propName: 'uniqueUsersCount', colorPropName: 'uniqueUsers', hideAverage: true },
        { label: 'avgPassPerc', totalValue: `${this.passAvgPlaysTotal ?? 0} %`, colorPropName: 'avgPassPerc', hideAverage: true }
      ]
    }
  },
  computed: {
    selectedStartDate () {
      return this.filter.startDate
    },
    selectedEndDate () {
      return this.filter.endDate
    },
    passAvgPlaysTotal () {
      const pass25plays = this.videoAssetPlayerPerfData.reduce((sum, perfDataItem) => sum + perfDataItem.pass25Plays, 0)
      const pass50plays = this.videoAssetPlayerPerfData.reduce((sum, perfDataItem) => sum + perfDataItem.pass50Plays, 0)
      const pass75plays = this.videoAssetPlayerPerfData.reduce((sum, perfDataItem) => sum + perfDataItem.pass75Plays, 0)
      const pass100plays = this.videoAssetPlayerPerfData.reduce((sum, perfDataItem) => sum + perfDataItem.pass100Plays, 0)

      return Math.round(
        (
          pass100plays * 100 +
          pass75plays * 75 +
          pass50plays * 50 +
          pass25plays * 25
        ) /
        (
          pass100plays +
          pass75plays +
          pass50plays +
          pass25plays
        )
      ) || 0
    },
    videoStatsChartData () {
      if (this.videoStatsData === null) {
        return {
          labels: [],
          datasets: []
        }
      }

      const datasets = [
        {
          label: this.$t('videoStats.videoplays'),
          borderColor: chartColors.videoPlays,
          backgroundColor: chartColors.videoPlays,
          fill: false,
          data: this.videoStatsData.videoPlays.metrics
        },
        {
          label: this.$t('videoStats.video_starts'),
          borderColor: chartColors.videoStarts,
          backgroundColor: chartColors.videoStarts,
          fill: false,
          data: this.videoStatsData.videoStarts.metrics
        },
        {
          label: this.$t('videoStats.replays'),
          borderColor: chartColors.replays,
          backgroundColor: chartColors.replays,
          fill: false,
          data: this.videoStatsData.replays.metrics
        },
        {
          label: this.$t('videoStats.videoviews'),
          borderColor: chartColors.videoViews,
          backgroundColor: chartColors.videoViews,
          fill: false,
          data: this.videoStatsData.videoPlays.metrics.map((videoPlay, index) => videoPlay + this.videoStatsData.replays.metrics[index])
        },
        {
          label: this.$t('videoStats.ad_starts'),
          borderColor: chartColors.adStarts,
          backgroundColor: chartColors.adStarts,
          fill: false,
          data: this.videoStatsData.adStarts.metrics
        },
        {
          label: this.$t('videoStats.unique_users_count'),
          borderColor: chartColors.playConversionRate,
          backgroundColor: chartColors.playConversionRate,
          fill: false,
          data: this.videoStatsData.uniqueUsersCount.metrics
        }
      ]

      return {
        labels: this.formatTimeGroupDates(this.videoStatsData.dimensions),
        datasets: datasets
      }
    }
  },
  watch: {
    'video.id' () {
      this.getVideoStats()
      this.getVideoAssetPlayerPerf()
    },
    selectedStartDate () {
      this.getVideoStats()
      this.getVideoAssetPlayerPerf()
    },
    selectedEndDate () {
      this.getVideoStats()
      this.getVideoAssetPlayerPerf()
    },
    passAvgPlaysTotal (newValue) {
      this.legendData[this.legendData.length - 1].totalValue = `${newValue} %`
    }
  },
  methods: {
    getDefaultStartDate () {
      const now = moment(this.video.createdAt)
      now.set({ hour: 0, minutes: 0, second: 0, millisecond: 0 })
      return now.toISOString()
    },
    getDefaultEndDate () {
      const end = moment()
      end.set({ hour: 0, minutes: 0, second: 0, millisecond: 0 })
      return end.toISOString()
    },
    formatTimeGroupDates (timeGroups, format = 'DD.MM HH:mm', addDay = 0) {
      return timeGroups.map(timeGroup => {
        if (addDay > 0) {
          return moment(timeGroup).add(addDay, 'days').format(format)
        }
        return moment(timeGroup).format(format)
      })
    },
    getVideoApiUrl (url) {
      const formatDate = (date) => moment(date).format('YYYY-MM-DD')
      const fromDate = formatDate(this.filter.startDate)
      const endDate = formatDate(this.filter.endDate)
      return `${url}?videoId=${this.video.id}&fromDate=${fromDate}&toDate=${endDate}`
    },
    getVideoStats () {
      const url = this.getVideoApiUrl('/videoDetails')
      this.loading = true
      dataHubVideoApi().get(url)
        .then(response => {
          this.videoStatsData = response.data
          this.videoStatsData.videoViews = {
            total: this.videoStatsData.videoPlays.total + this.videoStatsData.replays.total
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getVideoAssetPlayerPerf () {
      const url = this.getVideoApiUrl('/videoPlayerPerf')
      dataHubVideoApi().get(url)
        .then(response => {
          this.videoAssetPlayerPerfData = response.data
          this.videoAssetPlayerPerfData.forEach(playerPerf => {
            playerPerf.videoViews = playerPerf.videoPlays + playerPerf.replays
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    getStatsExportData () {
      if (!this.videoStatsData) {
        return []
      }
      const rows = this.videoStatsData.dimensions.map(
        (dateTime, idx) => [
          dateTime,
          this.videoStatsData.videoPlays.metrics[idx],
          this.videoStatsData.videoStarts.metrics[idx],
          this.videoStatsData.replays.metrics[idx],
          this.videoStatsData.adStarts.metrics[idx],
          this.videoStatsData.uniqueUsersCount.metrics[idx]
        ]
      )
      rows.unshift([
        '',
        this.videoStatsData.videoPlays.total,
        this.videoStatsData.videoStarts.total,
        this.videoStatsData.replays.total,
        this.videoStatsData.adStarts.total,
        this.videoStatsData.uniqueUsersCount.total,
        this.passAvgPlaysTotal
      ])
      rows.unshift([
        '',
        this.$i18n.t('videoStats.videoplays'),
        this.$i18n.t('videoStats.video_starts'),
        this.$i18n.t('videoStats.replays'),
        this.$i18n.t('videoStats.ad_starts'),
        this.$i18n.t('videoStats.unique_users_count'),
        this.$i18n.t('videoStats.avgPassPerc')
      ])
      return rows
    }
  }
}
</script>

<style lang="scss">
.form-edit-header {
  font: 400 rem(22px) "Roboto";
  color: #465674;
}
.tab {
  display: flex;
  gap: 1.3rem;
}
.video-performance-table {
    &__thead,
    &__tr {
      grid-template-columns: 200px repeat(auto-fit, minmax(80px, 1fr))
    }
  }
.video-preview {
  margin-top: 5rem;
}
</style>
