<template>
  <section>
    <label :class="{'error': error}">
      {{ $t('video.video_nature') }}
      <span class="required">*</span>
    </label><br>
    <CheckboxButton
      v-if="!disabled || (disabled && video.setting.editorialContent)"
      v-model="video.setting.editorialContent"
      :disabled="disabled"
      id="video_setting_editorialContent"
      data-test="video_setting_editorialContent"
      :label="$t('video.setting_editorial_content')"
      class="btn-toggle"
    />
    <CheckboxButton
      v-if="!disabled || (disabled && video.setting.agencyContent)"
      v-model="video.setting.agencyContent"
      :disabled="disabled"
      id="video_setting_agencyContent"
      data-test="video_setting_agencyContent"
      :label="$t('video.setting_agency_content')"
      class="btn-toggle"
    />
    <CheckboxButton
      v-if="!disabled || (disabled && video.setting.marketingContent)"
      v-model="video.setting.marketingContent"
      :disabled="disabled"
      id="video_setting_marketingContent"
      data-test="video_setting_marketingContent"
      :label="$t('video.setting_marketing_content')"
      class="btn-toggle"
    />
    <CheckboxButton
      v-if="!disabled || (disabled && video.setting.nativeContent)"
      v-model="video.setting.nativeContent"
      :disabled="disabled"
      id="video_setting_nativeContent"
      data-test="video_setting_nativeContent"
      :label="$t('video.setting_native_content')"
      class="btn-toggle"
    />
  </section>
</template>
<script>
import CheckboxButton from '@/components/form/inputs/InputCheckBox'

export default {
  name: 'NatureOfTheVideo',
  props: {
    video: {
      type: Object
    },
    error: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    editorialContent () {
      return this.video.setting.editorialContent
    },
    agencyContent () {
      return this.video.setting.agencyContent
    },
    marketingContent () {
      return this.video.setting.marketingContent
    },
    nativeContent () {
      return this.video.setting.nativeContent
    }
  },
  watch: {
    editorialContent (val) {
      if (val) {
        this.video.setting.editorialContent = true
        this.video.setting.agencyContent = false
        this.video.setting.marketingContent = false
        this.video.setting.nativeContent = false
      }
    },
    agencyContent (val) {
      if (val) {
        this.video.setting.editorialContent = false
        this.video.setting.agencyContent = true
        this.video.setting.marketingContent = false
        this.video.setting.nativeContent = false
      }
    },
    marketingContent (val) {
      if (val) {
        this.video.setting.editorialContent = false
        this.video.setting.agencyContent = false
        this.video.setting.marketingContent = true
        this.video.setting.nativeContent = false
      }
    },
    nativeContent (val) {
      if (val) {
        this.video.setting.editorialContent = false
        this.video.setting.agencyContent = false
        this.video.setting.marketingContent = false
        this.video.setting.nativeContent = true
      }
    }
  },
  components: {
    CheckboxButton
  }
}
</script>
