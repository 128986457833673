<template>
  <ModuleForm
    ref="videoForm"
    :module="video"
    :selected-tab="tabIndex"
    :tabs="tabs"
    @tab-change="changeTab"
  >
    <template #form-title>
      <VideoIcon class="form-header__icon" />
      {{ $t('moduleForm.video') }}
    </template>
    <template #header-buttons>
      <ModuleFormButton
        v-if="disabled"
        icon="edit"
        @click="goToEdit"
      />
      <ModuleFormButton
        v-else
        :title="$t('buttons.save')"
        @click="save"
      />
      <ModuleFormDeleteButton
        v-if="video.id"
        :record-id="video.id"
        store-name="video"
        list-router-path="video"
      />
      <BtnCopyToClipboard
        v-if="video.id"
        class="module-form-button module-form-button_copy"
        :data="video.id | toString"
        :notify-message="$t('notify.id_was_copied')"
      />
      <ModuleFormButton
        v-if="!isModal"
        icon="close"
        @click="$router.push('/video')"
      />
    </template>
    <template #form-content>
      <Preloader v-if="!dataLoaded" on-top />
      <!-- Main info -->
      <div  v-show="tabIndex === 0">
        <div
          class="form-header-info"
        >
          <table>
            <tr>
              <th>ID</th>
              <th>{{ $t('video.video_type_title') }}</th>
              <th v-if="video.liveboxAssetId !==''">{{ $t('video.livebox_asset_id') }}</th>
              <th v-if="video.youtubeId">{{ $t('video.youtube_id') }}</th>
              <th>{{ $t('moduleForm.createdBy') }}</th>
              <th>{{ $t('moduleForm.modifiedBy') }}</th>
              <th>{{ $t('video.status') }}</th>
            </tr>
            <tr>
              <td>{{ video.id }}</td>
              <td>{{ videoType }}</td>
              <td v-if="video.liveboxAssetId !==''">{{ video.liveboxAssetId }}</td>
              <td v-if="video.youtubeId">{{ video.youtubeId }}</td>
              <td>{{ createdBy }}</td>
              <td>{{ modifiedBy }}</td>
              <td>
                <VideoStatus
                  :video="video"
                />
              </td>
            </tr>
          </table>
        </div>
        <div class="form-content">
          <div class="form-content__left">
            <VideoMainContent
              :video="video"
              :data-loaded="dataLoaded"
              :validations="$v"
              :disabled="disabled"
            />
          </div>
          <div class="form-content__separator">
            <div class="form-content__separator__line"/>
          </div>
          <div class="form-content__right">
            <VideoMainSettings
              :video="video"
              :validations="$v"
              :disabled="disabled"
            />
          </div>
        </div>
      </div>
      <!-- Video -->
      <div v-show="tabIndex === 1">
        <div
          v-if="video.liveboxAssetId !== ''"
          class="form-header-info"
        >
          <table>
            <tr>
              <th v-if="video.liveboxAssetId !==''">{{ $t('video.livebox_asset_id') }}</th>
              <th v-if="video.youtubeId">{{ $t('video.youtube_id') }}</th>
              <th>{{ $t('video.video_duration') }}</th>
              <th>{{ $t('video.video_width') }}</th>
              <th>{{ $t('video.video_height') }}</th>
              <th>{{ $t('video.file_size') }}</th>
            </tr>
            <tr>
              <td v-if="video.liveboxAssetId !==''">{{ video.liveboxAssetId }}</td>
              <td v-if="video.youtubeId">{{ video.youtubeId }}</td>
              <td>{{ video.videoDuration | formatMilliseconds }}</td>
              <td>{{ video.videoWidth }}</td>
              <td>{{ video.videoHeight }}</td>
              <td>{{ getVideoSize(video.fileSize) }}</td>
            </tr>
          </table>
          <ModuleFormButton
            v-if="downloadData.src"
            :title="$t('video.download')"
            class="button-download"
            @click="downloadVideo"
          >
            <i class="fas fa-download"></i>
            <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            {{ $t('video.download') }}
          </ModuleFormButton>
        </div>
        <div class="form-content">
          <VideoUpload
            :video="video"
            :validations="$v"
            :disabled="disabled"
          />
        </div>
      </div>
      <!-- Annotations -->
      <div
        v-show="tabIndex === 2"
        class="form-content"
      >
        <VideoAnnotations
          v-if="['livebox'].some(v => v === video.type) && ['live','published'].some(v => v === video.status)"
          ref="video-annotations"
          :video="video"
          :disabled="disabled"
          @change="getVideo"
        />
        <div v-else class="alert alert-info">
          {{ $t('video.video_required_upload') }}
        </div>
      </div>
      <!-- Statistics -->
      <div
        v-show="tabIndex === 3"
        style="margin-top: 2.1875rem"
      >
        <VideoStats :video="video" />
      </div>
      <Modal
        v-if="cancelRequest"
        :title="$t('modal.download_progress')"
        @close="cancelDownload"
      >
        <template slot="body">
          <progress
            max="100"
            :value="downloadProgress"
            :time-left="downloadTimeLeft"
            :class="{ 'over4':  downloadProgress > 4 }"
          />
        </template>
      </Modal>
    </template>
  </ModuleForm>
</template>

<script>

import VideoTypeMixin from '@/components/mixins/valueObject/VideoTypeMixin'
import TinyMceConfig from '../../services/tinymce/TinyMceConfig'
import { required, minLength, maxLength, minValue, numeric } from 'vuelidate/lib/validators'
import VideoModel from '../../model/Video'
import NotifyService from '../../services/NotifyService'
import UserService from '../../services/user/UserService'
import CoreApi from '@/api/core'
import Axios from 'axios'
import Modal from '@/components/shared/Modal'
import { notifyServiceSetGeneralErrorMsg } from '@/services/NotifyServiceSetGeneralErrorMsg'
import VideoIcon from '@/assets/img/svg/video.svg?inline'
import PermissionService from '@/services/PermissionService'
import ModuleForm from '@/components/shared/ModuleForm'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import ModuleFormDeleteButton from '@/components/shared/ModuleFormDeleteButton'
import Preloader from '@/components/preloader/Preloader'
import BtnCopyToClipboard from '@/components/buttons/BtnCopyToClipboard'
import VideoMainContent from '../../components/video/VideoMainContent.vue'
import VideoMainSettings from '../../components/video/VideoMainSettings.vue'
import VideoUpload from '../../components/video/VideoUpload.vue'
import VideoAnnotations from '../../components/video/VideoAnnotations'
import VideoStatus from '../../components/video/VideoStatus'
import VideoStats from '../../components/video/VideoStats.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'VideoNewView',
  mixins: [VideoTypeMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    isModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      edit: false,
      saveData: true,
      tabs: [
        { name: this.$t('video.tabs.main'), error: false },
        { name: this.$t('video.tabs.video'), error: false },
        { name: this.$t('video.tabs.annotations'), error: false }
      ],
      autorefreshInterval: 0,
      videoArticles: [],
      downloadData: {},
      downloadProgress: 0,
      downloadTimeLeft: '',
      cancelRequest: null,
      dataLoaded: true,
      isLoading: false,
      video: this._.cloneDeep(VideoModel),
      richTextEditorConfig: TinyMceConfig.getConfig(),
      method: 'PUT',
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.VIDEO_PERMISSIONS
    }
  },
  computed: {
    ...mapGetters(['vlm']),
    isSaveButtonVisible () {
      return this.hasPermission(this.requiredPermissions.saveButton)
    },
    isDeleteButtonVisible () {
      return this.hasPermission(this.requiredPermissions.deleteButton)
    },
    tabIndex: {
      get () {
        return this.$store.getters['video/tabIndex']
      },
      set (newTab) {
        this.$store.commit('video/setTabIndex', newTab)
      }
    },
    videoType () {
      switch (this.video.type) {
        case 'youtube':
          return this.$t('video.video_type_value.youtube')
        case 'livebox':
          return this.$t('video.video_type_value.livebox')
        default:
          return ''
      }
    },
    createdBy () {
      if (!this.video.createdBy) {
        return ''
      }
      return `${this.userNameById(this.video.createdBy)} (${this.prettyDateTime(this.video.createdAt)})`
    },
    modifiedBy () {
      if (!this.video.modifiedBy) {
        return ''
      }
      return `${this.userNameById(this.video.modifiedBy)} (${this.prettyDateTime(this.video.modifiedAt)})`
    },
    users () {
      return this.$store.getters['user/all']
    },
    videoShows () {
      const videoShows = this.$store.getters['videoShow/all']
      return videoShows.filter(videoShow => {
        if (videoShow.enabled) {
          return videoShow
        }
        if (this.video.videoShows && this.video.videoShows.includes(videoShow.id)) {
          return videoShow
        }
      })
    },
    videoCategories () {
      const videoCategories = this.$store.getters['videoCategory/all']
      return videoCategories.filter(videoCategory => {
        if (videoCategory.enabled) {
          return videoCategory
        }
        if (this.video.videoCategories && this.video.videoCategories.includes(videoCategory.id)) {
          return videoCategory
        }
      })
    },
    showVideoUploadButton () {
      if (this.video.status === 'published') {
        return true
      }
      if (this.$v.$invalid) {
        return false
      }
      return true
    },
    categories () {
      return this.$store.getters['category/all']
    },
    isNatureOfVideo () {
      if (this.video.setting.editorialContent ||
        this.video.setting.agencyContent ||
        this.video.setting.marketingContent ||
        this.video.setting.nativeContent
      ) {
        return true
      }
      return ''
    },
    isVideoSourceType () {
      if (this.video.setting.internal || this.video.setting.social || this.video.setting.agency ||
        this.video.setting.camera || this.video.setting.mobilePhone || this.video.setting.slideshow ||
        this.video.setting.show) {
        return true
      }
      return ''
    }
  },
  validations: {
    video: {
      defaultSite: {
        required,
        minValue: minValue(1)
      },
      safetyTopics: {
        required
      },
      categories: {
        required
      },
      authorUsers: {
        required
      },
      setting: {
        episodeNumber: {
          numeric,
          minValue: minValue(1)
        },
        dynamicPreviewStart: {
          numeric,
          minValue: minValue(5)
        },
        dynamicPreviewDuration: {
          numeric,
          minValue: minValue(2)
        }
      },
      meta: {
        title: {
          required,
          minLength: minLength(50),
          maxLength: maxLength(95)
        },
        description: {
          required,
          minLength: minLength(10),
          maxLength: maxLength(300)
        },
        keywords: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(250)
        },
        gdprSource: {
          minLength: minLength(0),
          maxLength: maxLength(255)
        }
      }
    },
    isNatureOfVideo: {
      required
    },
    isVideoSourceType: {
      required
    }
  },
  components: {
    VideoAnnotations,
    VideoIcon,
    ModuleForm,
    Modal,
    ModuleFormButton,
    ModuleFormDeleteButton,
    BtnCopyToClipboard,
    VideoMainContent,
    VideoMainSettings,
    VideoUpload,
    VideoStatus,
    VideoStats,
    Preloader
  },
  watch: {
    video: {
      handler () {
        this.saveData = true
      },
      deep: true
    }
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    changeTab (index) {
      if (!this.disabled && this.saveData && this.tabIndex === 0 && index > 0) {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          this.save()
          this.tabIndex = index
        } else {
          this.$refs.videoForm.activeTab = this.tabIndex
          NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        }
      } else {
        this.tabIndex = index
      }
    },
    cancelDownload () {
      this.cancelRequest.cancel()
      this.cancelRequest = null
      this.downloadTimeLeft = ''
      this.downloadProgress = 0
    },
    async downloadVideo () {
      this.cancelRequest = Axios.CancelToken.source()
      const encoder = new TextEncoder()
      const credentials = `${this.downloadData.username}:${this.downloadData.password}`
      const encodedCredentials = encoder.encode(credentials)
      const base64Credentials = btoa(String.fromCharCode.apply(null, encodedCredentials))
      const startTime = new Date().getTime()

      return await Axios.get(this.downloadData.src, {
        responseType: 'blob',
        headers: {
          Authorization: 'Basic ' + base64Credentials
        },
        onDownloadProgress: ({ loaded, total }) => {
          const elapsedTime = (new Date().getTime()) - startTime
          const chunksPerTime = loaded / elapsedTime
          const estimatedTotalTime = total / chunksPerTime
          const timeLeft = estimatedTotalTime - elapsedTime
          this.downloadTimeLeft = this.$options.filters.formatMilliseconds(timeLeft + 1000)
          this.downloadProgress = Math.floor(loaded / total * 100)
        },
        cancelToken: this.cancelRequest.token
      })
        .then(response => {
          this.downloadProgress = 0
          this.cancelRequest = null
          this.downloadTimeLeft = ''
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', this.video.fileName)
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => {
          console.error(error)
          this.cancelRequest = null
          notifyServiceSetGeneralErrorMsg(error)
        })
    },
    setAutorefresh () {
      clearInterval(this.autorefreshInterval)
      if (this.video.status === 'processing') {
        this.autorefreshInterval = setInterval(() => {
          this.$store.dispatch('video/fetchOne', this.video.id)
            .then(async () => {
              this.video = this.$store.getters['video/detail']
              this.$nextTick(() => {
                this.saveData = false
                if (this.video.status !== 'processing') {
                  clearInterval(this.autorefreshInterval)
                }
              })
            })
            .catch(error => {
              console.log(error)
            })
        }, 10000)
      }
    },
    getVideo () {
      this.loopAnnotations((annotation) => this.setAnnotationWatcher(annotation, true))
      this.dataLoaded = false
      this.$store.dispatch('video/fetchOne', this.$route.params.id)
        .then(async () => {
          this.video = this.$store.getters['video/detail']
          if (this.video.liveboxAssetId) {
            this.downloadData = (await CoreApi().get(`/livebox/${this.video.liveboxAssetId}/request_video_download`)).data
          }
          this.$nextTick(() => {
            this.saveData = false
            this.setAutorefresh()
          })
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.dataLoaded = true
          this.loopAnnotations((annotation) => this.setAnnotationWatcher(annotation, false))
        })
    },
    goToEdit () {
      this.$router.push('/video/' + this.video.id + '/edit')
    },
    loopAnnotations (fn) {
      const videoAnnotationsRef = this.$refs['video-annotations']
      if (videoAnnotationsRef) {
        const annotationsRef = videoAnnotationsRef.$refs.annotations
        if (annotationsRef && annotationsRef.length > 0) {
          for (const annotation of annotationsRef) {
            fn(annotation)
          }
        }
      }
    },
    validateAnnotation (annotation, valid) {
      if (annotation.showSaveAnnotation || !annotation.annotation.id) {
        valid.value = false
      }
    },
    setAnnotationWatcher (annotation, disable) {
      annotation.disableChangeWatcher = disable
    },
    async save () {
      const validAnnotations = { value: true }
      this.loopAnnotations((annotation) => this.validateAnnotation(annotation, validAnnotations))

      if (!validAnnotations.value) {
        NotifyService.setErrorMessage(this.$t('notify.please_save_all_annotations'))
        return
      }
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
      } else {
        this.loopAnnotations((annotation) => this.setAnnotationWatcher(annotation, true))
        this.dataLoaded = false
        this.$store.dispatch(`video/${this.edit ? 'update' : 'create'}`, this.prepareRequest(this.video))
          .then(() => {
            if (this.$store.getters['video/error'] === null) {
              if (this.edit) {
                NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
              } else {
                NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
              }
            } else {
              NotifyService.setErrorMessage(this.$store.getters['video/error'])
            }
            this.video = this.$store.getters['video/detail']
            this.$nextTick(() => {
              if (!this.edit) {
                this.goToEdit()
              }
              this.saveData = false
            })
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.dataLoaded = true
            this.loopAnnotations((annotation) => this.setAnnotationWatcher(annotation, false))
          })
      }
    },
    getVideoShows () {
      this.$store.dispatch('videoShow/fetchAll')
      this.$store.dispatch('videoCategory/fetchAll')
    },
    prepareRequest (video) {
      const deleteKeys = [
        'annotations',
        'articleDocumentId',
        'articles',
        'brightcoveId',
        'entityUuid',
        'expanded',
        'createdAt',
        'createdBy',
        'liveboxAssetId',
        'modifiedAt',
        'modifiedBy',
        'ooyalaId',
        'statusMessage',
        'type',
        'videoDuration',
        'videoHeight',
        'videoWidth',
        'youtubeId'
      ]
      if (video.expanded.previewImage) {
        video.previewImage = video.expanded.previewImage.id
      }

      const payload = this._.cloneDeep(video)
      for (const key in video) {
        if (deleteKeys.includes(key)) {
          delete payload[key]
        }
      }
      return payload
    },
    userNameById (id) {
      const user = this.$store.getters['user/userById'](id)
      if (user) {
        return UserService.getUserInfo(user)
      }
      return id
    },
    getVideoSize (fileSize) {
      if (fileSize) {
        return Math.round(fileSize * 1000 / 1048576) / 1000 + ' MB'
      } else {
        return ''
      }
    },
    prettyDateTime (dateTime) {
      return dateTime ? this.$options.filters.prettyDateTime(dateTime) : ''
    }
  },
  created () {
    if (!this.vlm) {
      this.getVideoShows()
    }
  },
  beforeDestroy () {
    clearInterval(this.autorefreshInterval)
  }
}
</script>

<style lang="scss" scoped>
.article-info:hover {
  color: #fff;
}
progress {
  height: rem(50px);
  width: 100%;
  margin-top: rem(20px);
  &:after {
    content: attr(value)'% ('attr(time-left)'⌛)';
    display: block;
    margin-top: rem(12px);
    margin-left: rem(10px);
    color: black;
    font-weight: 700;
  }
}
.over4::after {
  color: white;
}
::v-deep {
  .form-header-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;
    padding: rem(10px);
    margin-bottom: 1rem;
    border-radius: rem(6px);
    td, th {
      font-size: rem(14px);
      padding-right: 1rem;
    }
    th {
      font-weight: 700;
      color: #465674;
    }
    td {
      color: #8490A7;
      font-weight: 400;
    }
  }
  .btn,
  .export-button {
    position: relative;
    display: inline-block;
    border-radius: 0.25rem !important;
    margin-right: 0.5rem;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    font: 500 0.8125rem "Roboto";
    padding: 0.4375rem 0.875rem;
    text-overflow: ellipsis;
    color: #fff !important;
    background: #6599FE !important;
    border: none !important;
    &.detail-icon {
      color: #333 !important;
      background-color: #fff !important;
      border: 1px solid #ccc !important;
      &:hover {
        background: #fff !important;
      }
    }
    &-toggle {
      color: #465674 !important;
      background: #fff !important;
      border: 1px solid #465674 !important;
      &.active {
        color: #fff !important;
        background: #6599FE !important;
        border-color: #6599FE !important;
      }
    }
    &:hover {
      opacity: initial !important;
      color: #fff !important;
      box-shadow: none !important;
      background: #3277fe !important;
      border-color: #3277fe !important;
    }
    &-danger, &-danger:hover {
      background: #E9596F !important;
      border: 1px solid #E9596F !important;
    }
  }
}
.btn-media-upload {
  margin-right: 0.5rem;
}
.module-form-button {
  height: 1.75rem;
}
::v-deep .module-form-button_copy {
  width: 1.625rem;
  span {
    width: initial !important;
    height: 100% !important;
  }
}
</style>
