<template>
  <p class="article-total-count">
    {{ $t('found') }}: {{ totalCount }}
  </p>
</template>

<script>
export default {
  name: 'ArticleTotalCount',
  props: {
    totalCount: {
      type: [Number, String],
      default: 0
    }
  }
}
</script>

<style scoped lang="scss">
  .article-total-count {
    font-family: "Roboto", sans-serif;
    font-size: rem(16px);
    font-weight: 400;
    color: #8A96AC;
    margin: 0;
  }
</style>
