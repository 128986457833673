<template>
  <div
    v-if="videoSrc"
    class="thumbnail-container"
  >
    <div class="thumbnail-slider">
      <VueSlider
        v-if="snapshotCreated"
        v-model="percentualPosition"
        :min="min"
        :max="max"
        dotSize="20"
        tooltip="none"
      />
    </div>
    <VueVideoThumbnail
      :video-src="videoSrc"
      :snapshot-at-duration-percent="percentualPosition"
      :width="width"
      :height="height"
      :chunks-quantity="10"
      @snapshotCreated="snapshotCreated = true"
    >
      <template #snapshot="{snapshot}">
        <img
          v-if="snapshot"
          :src="snapshot"
          alt="video thumbnail"
        >
      </template>
    </VueVideoThumbnail>
    <div class="thumbnail-buttons">
      <ModuleFormButton
        v-if="snapshotCreated"
        class="thumbnail-buttons__confirm"
        :title="$t('buttons.confirm_and_upload')"
        @click="$emit('set-thumbnail')"
      />
    </div>
  </div>
</template>

<script>
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import VueVideoThumbnail from 'vue-video-thumbnail'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  name: 'VideoThumbnail',
  components: {
    ModuleFormButton,
    VueVideoThumbnail,
    VueSlider
  },
  props: {
    videoSrc: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 225
    },
    min: {
      type: Number,
      default: 5
    },
    max: {
      type: Number,
      default: 95
    }
  },
  data () {
    return {
      percentualPosition: 50,
      snapshotCreated: false
    }
  }
}
</script>

<style lang="scss" scoped>
.thumbnail-container {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .thumbnail-slider {
    width: 100%;
    .vue-slider {
      margin: 1rem 0;
    }
  }
  .thumbnail-buttons__confirm {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    margin-top: 1rem;
  }
}
</style>
