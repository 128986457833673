<template>
  <div class="stats-info-value">
    <div class="title">
      <span class="dot" :style="'background-color:' + this.chartColors[statsValue.colorPropName ? statsValue.colorPropName : statsValue.propName]" />
      {{ $t(`videoStats.${statsValue.label}`) }}
    </div>
    <div class="legend">
      <div>
        {{ $t('videoStats.total') }}
        <span v-if="statsValue.totalValue || statsValue.totalValue === 0">
          {{ statsValue.totalValue }}
        </span>
        <span v-else-if="statsValue.format === 'percentage'">
          {{ videoStatsData[statsValue.propName].total | formatPercentage }}
        </span>
        <span v-else>
          {{ videoStatsData[statsValue.propName].total | formatNumber }}
        </span>
      </div>
      <div v-if="!statsValue.hideAverage">
        {{ $t('videoStats.dailyAvg') }}<span>{{ videoStatsData[statsValue.propName].dailyAverage | formatNumber }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoStatsInfoValue',
  props: {
    videoStatsData: {
      type: Object,
      required: true
    },
    chartColors: {
      type: Object,
      required: true
    },
    statsValue: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.stats-info-value {
  @include bp(14) {
    min-width: 140px;
  }
}
.dot {
  height: rem(20px);
  width: rem(20px);
  border-radius: 50%;
  display: inline-block;
  vertical-align: text-bottom;
}
.title {
  @include font(700 13px "Roboto");
  color: #465674;
  text-transform: uppercase;
  margin-top: rem(10px);
  margin-bottom: rem(8px);
}
.legend {
  @include font(400 14px "Roboto");
  color: #465674;

  div {
    float: left;
    width: 100%;
    margin-bottom: rem(4px);

    span {
      font-weight: bold;
      float: right;
    }
  }
}
</style>
